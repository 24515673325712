import { apiTokenBasedRequest } from '@/services/ApiService';
import Environment from '@/config/environment';
import router from '@/router';

/**
 * Method to create request to payment
 *
 * @param authToken
 * @param docPersonId
 * @returns {Promise<*>}
 */
export const createPayment = ({ authToken }) => {
  return apiTokenBasedRequest('POST', '/api/v1/auth/microTransaction/payment', authToken, {
    continue_url:
      Environment.getAppUrl() +
      router.resolve({
        name: 'authentication-micropayment',
        params: { authToken },
        query: {
          returnFromPayU: 'true',
        },
      }).href,
  });
};

/**
 * Method to check payment status
 *
 * @param authToken
 * @returns {Promise<*>}
 */
export const checkPaymentStatus = ({ authToken }) => {
  return apiTokenBasedRequest('GET', '/api/v1/auth/microTransaction/payment', authToken);
};
