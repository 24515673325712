<template>
  <Loader v-if="isLoading" />

  <v-card
    v-else
    :color="workspaceLayerColor"
    class="mx-auto my-md-10 pt-10 text-center"
    width="100%"
    max-width="45rem"
  >
    <AuthsProgressBar :step="3" />

    <v-card-text class="px-10 px-md-15">
      <h1 class="headline font-weight-black secondary--text mb-2">
        {{ $t('aml.microtransaction2') }}
      </h1>
    </v-card-text>

    <InfoBox :message="$t('aml.micro_payment.info_message', { amount: '10 Kč' })" />

    <!--    <v-row class="mt-5 px-md-8" align="center" justify="space-between">-->
    <!--      <v-col class="col-12 col-md-8">-->
    <!--        <v-row class="px-5 font-weight-medium" align="center" no-gutters>-->
    <!--          <v-col cols="6" class="py-1 pl-0 text-left">-->
    <!--            {{ $t('workspace.bank_account.account_number') }}:-->
    <!--          </v-col>-->
    <!--          <v-col cols="6" class="py-1 pl-0 text-left">-->
    <!--            {{ accountNumber }}-->
    <!--            <v-icon @click="copyToClipboard(accountNumber)">-->
    <!--              mdi-content-copy-->
    <!--            </v-icon>-->
    <!--          </v-col>-->
    <!---->
    <!--          <v-col cols="6" class="py-1 pl-0 text-left">-->
    <!--            {{ $t('aml.micro_payment.variable_symbol') }}:-->
    <!--          </v-col>-->
    <!--          <v-col cols="6" class="py-1 pl-0 text-left">-->
    <!--            {{ variableSymbol }}-->
    <!--            <v-icon @click="copyToClipboard(variableSymbol)">-->
    <!--              mdi-content-copy-->
    <!--            </v-icon>-->
    <!--          </v-col>-->
    <!---->
    <!--          <v-col cols="6" class="py-1 pl-0 text-left">-->
    <!--            {{ $t('contract.reject.note') }}:-->
    <!--          </v-col>-->
    <!--          <v-col cols="6" class="py-1 pl-0 text-left">-->
    <!--            {{ paymentNote }}-->
    <!--            <v-icon-->
    <!--              v-if="paymentNote !== ''"-->
    <!--              @click="copyToClipboard(paymentNote)"-->
    <!--            >-->
    <!--              mdi-content-copy-->
    <!--            </v-icon>-->
    <!--          </v-col>-->
    <!--        </v-row>-->
    <!--      </v-col>-->
    <!---->
    <!--      <v-col class="col-12 col-md-4">-->
    <!--        <v-avatar v-if="qrPaymentUrl !== null" rounded size="200">-->
    <!--          <img :src="qrPaymentUrl" alt="" />-->
    <!--        </v-avatar>-->
    <!--      </v-col>-->
    <!--    </v-row>-->

    <!--    <v-row-->
    <!--      class="mt-5 mx-5 mx-md-12 py-0 my-0 micropayment-check"-->
    <!--      align="center"-->
    <!--      justify="center"-->
    <!--    >-->
    <!--      <v-col cols="auto" class="py-0">-->
    <!--        <v-checkbox-->
    <!--          v-model="statement"-->
    <!--          :label="$t('aml.micro_payment.statement')"-->
    <!--        ></v-checkbox>-->
    <!--      </v-col>-->
    <!--    </v-row>-->

    <!--    <v-card-actions class="px-10 mt-5 mb-15">-->
    <!--      <v-row align="center" justify="space-between">-->
    <!--        <v-col class="text-center text-md-right">-->
    <!--          <v-btn-->
    <!--            color="primary"-->
    <!--            min-width="15rem"-->
    <!--            :loading="isSubmitted"-->
    <!--            :disabled="!canContinue"-->
    <!--            :block="$vuetify.breakpoint.mobile"-->
    <!--            x-large-->
    <!--            @click="submit"-->
    <!--          >-->
    <!--            {{ $t('general.continue') }}-->
    <!--          </v-btn>-->
    <!--        </v-col>-->
    <!--      </v-row>-->
    <!--    </v-card-actions>-->
    <v-card-actions class="px-10 mt-5 mb-15">
      <v-row align="center" justify="center">
        <v-col cols="auto" class="text-center">
          <v-btn
            color="primary"
            min-width="15rem"
            :loading="isPayOnlineInProcess"
            :block="$vuetify.breakpoint.mobile"
            x-large
            @click="payOnline"
          >
            <v-avatar size="70">
              <v-img src="/images/PAYU_LOGO_WHITE.png" alt="payu_logo" contain />
            </v-avatar>
            {{ $t('general.pay_online') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>

    <AlertMessage v-model="error" />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { checkPaymentStatus, createPayment } from '@verification/services/openPayUPaymentsService';
import { goToLinkWithTarget } from '@/common/reusable/routing';
// import axios from 'axios';
import AuthsProgressBar from '@verification/components/AuthsProgressBar';
import InfoBox from '@verification/components/InfoBox';
import Loader from '@/components/Loader';
import AlertMessage from '@verification/components/AlertMessage';
import { authMicroTransactionSeen } from '@verification/services/logService';

// const createCzQrCodeEndpoint = ({ micropayment }) => {
//   const ret = [];
//   const endpoint = 'https://api.paylibo.com/paylibo/generator/czech/image';
//   const params = {
//     accountPrefix: micropayment.bank_account?.prefix || null,
//     accountNumber: micropayment.bank_account?.account_number || null,
//     bankCode: micropayment.bank_account?.bank_code || null,
//     amount: '10',
//     currency: 'CZK',
//     vs: micropayment.variable_symbol || null,
//     message: micropayment.note || null,
//     branding: true,
//     size: 200,
//   };
//
//   Object.keys(params).forEach((param) => {
//     if (params[param] !== null) {
//       ret.push(
//         `${encodeURIComponent(param)}=${encodeURIComponent(params[param])}`
//       );
//     }
//   });
//
//   return `${endpoint}?${ret.join('&')}`;
// };

export default {
  name: 'Micropayment',
  components: {
    AuthsProgressBar,
    InfoBox,
    Loader,
    AlertMessage,
  },
  data() {
    return {
      error: false,
      loaded: false,
      statement: false,
      submitted: false,
      qrCodeSrc: null,
      payOnlineProcess: false,
    };
  },
  computed: {
    ...mapGetters({
      isWithoutMicroTransactionVerification:
        'authsMicroTransaction/isWithoutMicroTransactionVerification',
      microTransaction: 'authsMicroTransaction/microTransaction',
      workspaceLayerColor: 'auths/workspaceLayerColor',
    }),
    canContinue() {
      return this.statement;
    },
    accountNumber() {
      return `${this.microTransaction.bank_account.account_number}/${this.microTransaction.bank_account.bank_code}`;
    },
    variableSymbol() {
      return this.microTransaction.variable_symbol;
    },
    paymentNote() {
      return this.microTransaction.note || '';
    },
    qrPaymentUrl() {
      return this.qrCodeSrc;
    },
    isLoading() {
      return !this.loaded;
    },
    isSubmitted() {
      return this.submitted;
    },
    isPayOnlineInProcess() {
      return this.payOnlineProcess;
    },
  },
  created() {
    this.fetchAuths(this.$route.params.authToken).then(() => {
      this.loaded = true;

      // const qrCodeSrc = createCzQrCodeEndpoint({
      //   micropayment: this.microTransaction,
      // });
      // axios
      //   .get(qrCodeSrc)
      //   .then(() => {
      //     this.qrCodeSrc = qrCodeSrc;
      //     this.loaded = true;
      //   })
      //   .catch(() => {
      //     this.qrCodeSrc = null;
      //     this.loaded = true;
      //   });
    });
  },
  beforeMount() {
    if (this.isWithoutMicroTransactionVerification) {
      return this.$router.push({
        name: 'authentication-summary',
        params: {
          authToken: this.$route.params.authToken,
        },
      });
    }
  },
  mounted() {
    if (this.isWithoutMicroTransactionVerification) {
      return;
    }

    if ('true' === this.$route?.query?.returnFromPayU) {
      this.payOnlineProcess = true;

      const self = this;
      const authToken = this.$route.params.authToken;
      const paymentStatusFetcher = setInterval(async () => {
        const payment = await checkPaymentStatus({ authToken });

        if (payment.status !== 'NEW') {
          clearInterval(paymentStatusFetcher);
          self
            .submitMicroTransaction({ authToken })
            .then(() => {
              return self.$router.push({
                name: 'authentication-summary',
                params: { authToken },
              });
            })
            .catch(() => {
              self.error = true;
            });
        }
      }, 2500);
    } else {
      authMicroTransactionSeen({ authToken: this.$route.params.authToken });
    }
  },
  methods: {
    ...mapActions({
      fetchAuths: 'auths/fetchAuths',
      setModeEditing: 'auths/setModeEditing',
      submitMicroTransaction: 'authsMicroTransaction/submitMicroTransaction',
    }),
    // copyToClipboard(text) {
    //   const dummy = document.createElement('input');
    //   document.body.appendChild(dummy);
    //   dummy.value = text;
    //   dummy.select();
    //   document.execCommand('copy');
    //   document.body.removeChild(dummy);
    // },
    payOnline() {
      this.payOnlineProcess = true;
      this.setModeEditing(false);

      return createPayment({ authToken: this.$route.params.authToken })
        .then((resp) => {
          return goToLinkWithTarget(resp.gateway_link, '_self');
        })
        .catch(() => {
          this.error = true;
          this.payOnlineProcess = false;
        });
    },
    // submit() {
    //   const authToken = this.$route.params.authToken;
    //
    //   this.submitted = true;
    //   this.setModeEditing(false);
    //   this.submitMicroTransaction({ authToken })
    //     .then(() => {
    //       return this.$router
    //         .push({
    //           name: 'authentication-summary',
    //           params: { authToken },
    //         })
    //         .then(() => {
    //           this.submitted = false;
    //         });
    //     })
    //     .catch(() => {
    //       this.submitted = false;
    //       this.error = true;
    //     });
    // },
  },
};
</script>

<style lang="scss" scoped>
.micropayment-check {
  border-top: 1px solid #dee7f4;
  border-bottom: 1px solid #dee7f4;
}
</style>
